import React, { useContext, useEffect, useState } from "react"
import { Container, Jumbotron, Button } from "reactstrap"
import Layout from "../components/layout/layout"
import { navigate } from "gatsby"
import "../styles/homepage.scss"

const benefits = [
  {
    title: "Source Code Management",
    body:
      "Securely share code across teams and organizations with encrypted Git services, customizable workflows and auditability",
    icon: "/source-code.svg",
  },
  {
    title: "Continuous Integration",
    body:
      "Continuously build and test on a wide range of hardware, ensuring software quality, performance and compliance.",
    icon: "/continuous-integration.svg",
  },
  {
    title: "Secure Delivery",
    body: "Document, version and share releases for a solid customer experience",
    icon: "/secure-delivery.svg",
  },
  {
    title: "Collaborative Engineering",
    body:
      "Collaborate with your customers, partners and community for high quality, secure distribution all in one place.",
    icon: "/collaborative-engineering.svg",
  },
]

const applications = [
  "Get access to an expanding repository of versatile CI job templates, designed to streamline integration across various project pipelines.",
  "Find ready-to-use solutions to make your development process more efficient and less complex.",
  "Utilize our plug-and-play solutions with the flexibility to customize as needed, enhancing pipeline efficiency while reducing setup time and complexity.",
]

const features = [
  {
    title: "Built on GitLab CI & AWS",
    body:
      "CodeLinaro CI guarantees optimal performance, scalability and reliability in every build and deployment.",
  },
  {
    title: "Docker-in-Docker Support",
    body:
      "CodeLinaro CI elevates containerization with Docker-in-Docker support for flexible, efficient build, testing and deployment.",
  },
  {
    title: "Arm Architecture Support",
    body:
      "CodeLinaro CI accelerates Arm and x86-based builds, optimizing development cycles and enhancing the user experience of building and deploying applications.",
  },

  {
    title: "Flexible Billing",
    body:
      "CodeLinaro CI sets itself apart with granular expense allocation to specific projects, ensuring financial flexibility and precise budget control to your entire organization.",
  },
  {
    title: "Persistent Storage for projects",
    body:
      "CodeLinaro CI integrates persistent storage for data integrity and streamlined workflows, reducing costs and improving the overall development process.",
  },
  {
    title: "Intuitive Dashboard",
    body:
      "CodeLinaro CI’s dashboard provides at glance job monitoring and quick access to analytics for informed decisions.",
  },
  {
    title: "GPU Power",
    body:
      "Enable GPU support in CodeLinaro CI to boost efficiency and costs for compute-intensive tasks.",
  },
]

export default function Home() {

  // window.onload=()=>{
  //   var url=window.location.href;//得到网址路径
  //   console.log(document.getElementById('ciClick'))
  //   if (url.includes('#ci')){
  //     console.log(url,"6666666")
  //     var oA = document.createElement("a"); //创建a标签
  //     oA.href = "#ci"; //添加 href 属性
  //     oA.click(); //模拟点击
  //   }
  // }
  const [contentRef, setContentRef] = useState(null)
  useEffect(() => {
    const url = window.location.href//得到网址路径
    console.log(document.getElementById("ciClick"))
    if (url.includes("#ci")) {
      if (contentRef != null) {
        contentRef.click()
      }
    }
  }, [contentRef])
  var isss = typeof sessionStorage === "undefined"
  var isbrowser = typeof window === "undefined"
  if (!isss && sessionStorage.hasOwnProperty("theAuthUser")) {
    var rurl = `${process.env.AUTH0_LOGOUT}user/registration/incomplete`
    // console.log(ir, rurl)
    if (!isbrowser) window.location.href = rurl
    return null
  } else if (!isss && !isbrowser)
    return (
      <Layout pageTitle="Welcome to CodeLinaro" pageName="homepage">
        <aside
          style={{
            backgroundColor: "#8A2C87",
            color: "white",
            textAlign: "center",
            padding: "1rem 1rem",
          }}
        >
          <a href="#ci" style={{ color: "white" }}>
            <span ref={setContentRef}>New to CodeLinaro! | Continuous Integration - READ MORE</span>
          </a>
        </aside>
        <section
          style={{
            minHeight: "35rem",
            backgroundImage: "url(/codelinarobackground.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              minHeight: "35rem",
              backgroundImage: "url(/green_swoosh.png)",
              backgroundPosition: "bottom",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "3% 10%",
              paddingBottom: "2rem",
              position: "relative",
            }}
          >
            <img
              src="/CL_transparent.png"
              alt="CL_transparent"
              style={{
                position: "absolute",
                height: "40%",
                right: "10%",
                top: "0%",
                bottom: "10%",
                margin: "auto",
              }}
            />
            <h1
              className="display-4 text-dark font-weight-bold"
              style={{ display: "block", maxWidth: "35ch", marginTop: "2rem", fontSize: "3rem" }}
            >
              Revolutionize Your Embedded Software Development with CodeLinaro.
            </h1>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "end",
                width: "100%",
                maxWidth: "100ch",
                marginTop: "2rem",
              }}
            >
              <div style={{ maxWidth: "50ch", fontWeight: "bold", fontSize: "1.5rem" }}>
                <p>
                  CodeLinaro helps you navigate the complexities of the embedded software
                  development life cycle.
                </p>
              </div>
              <Button
                color="primary"
                className="viewPublicProjects"
                onClick={() => {
                  navigate(`/projects/`)
                }}
              >
                <img src="/favicon.png" alt="favicon" />
                View Public projects
              </Button>
            </div>
          </div>
        </section>
        <section>
          <ul
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              marginTop: "0.25rem",
              backgroundColor: "#5A8D80",
              padding: "6rem 0",
              color: "white",
            }}
          >
            {benefits.map(({ title, body, icon }) => (
              <li
                key={title}
                style={{
                  listStyle: "none",
                  flexBasis: "20%",
                  padding: "1.5rem",
                  margin: "0 0.5rem",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minWidth: "min(50ch, 100%)",
                }}
              >
                <img
                  src={icon}
                  style={{ width: "60px", aspectRatio: "square", marginBottom: "1.25rem" }}
                />
                <h3 style={{ fontSize: "1.125rem", marginBottom: "0.5rem" }}>{title}</h3>
                <p style={{ fontWeight: "normal", fontSize: "1rem" }}>{body}</p>
              </li>
            ))}
          </ul>
        </section>
        <section style={{ padding: "4rem 2%", margin: "0 auto" }} id="ci">
          <h2 style={{ fontWeight: "bold", marginBottom: "4rem", textAlign: "center" }}>
            Choose CodeLinaro CI - ensure your CI practices remain secure, reliable & scalable.
          </h2>
          <ul
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              margin: "0 auto",
              fontSize: "1.125rem",
              padding: 0,
            }}
          >
            {features.map(feature => (
              <li
                style={{
                  margin: "1rem 2rem",
                  listStyle: "none",
                  padding: "1rem",
                  // flexGrow: "1",
                  backgroundColor: "white",
                  basis: "50%",
                  width: "min(50ch, 100%)",
                }}
              >
                <h3 style={{ fontWeight: "bold", fontSize: "1.125rem" }}>{feature.title}</h3>
                <p>{feature.body}</p>
              </li>
            ))}
          </ul>
        </section>
        <section style={{ backgroundColor: "#AFC8C2", padding: "4rem 5%" }}>
          <ul
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              maxWidth: "80rem",
              margin: "0 auto",
              padding: 0,
            }}
          >
            {applications.map(application => (
              <li
                style={{
                  margin: "0.5rem auto",
                  listStyle: "none",
                  backgroundColor: "white",
                  padding: "2rem",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  fontWeight: "bold",
                  maxWidth: "min(30ch, 100%)",
                }}
              >
                {application}
              </li>
            ))}
          </ul>
        </section>
        <div className="requestInformation">
          <Button
            color="primary"
            style={{
              color: "white",
              fontWeight: "bold",
            }}
            onClick={() => {
              navigate(`/contact/`)
            }}
          >
            <img src="/favicon_white.svg" alt="favicon_white" />
            Request Demo Now
          </Button>
        </div>
      </Layout>
    )
  else return null
}
